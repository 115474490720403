import React from "react";
import Modal from 'react-modal';
import { createGlobalStyle, css} from "styled-components";

const Layout = ({ children, noImageBg, imageBgUrl }) => {
    const imageBgStyles = css`
    background-color: white;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-image: url(${imageBgUrl});
    
    &:after{
        content:"";
        position:fixed; /* stretch a fixed position to the whole screen */
        top:0;
        height:100vh; /* fix for mobile browser address bar appearing disappearing */
        left:0;
        right:0;
        background-image: inherit;
        z-index:-1; /* needed to keep in the background */
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
`;

    const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    font-size: 62.5%;
    line-height: 145%;
  }
  
  html {
    overflow-x: hidden;
    min-height: 100vh;
  }

  body {
    height: 100vh;
    font-size: 1.3rem;
    line-height: 20px;
    font-family: "Roboto";
    font-weight: 400;
    overflow-x: hidden;
    ${({ noImageBg }) => !noImageBg && imageBgStyles}
  }
 
  h1, h2 {
    line-height: 125%;
  }
  .ReactModal__Overlay--after-open {
      opacity: 1 !important;
      
      .ReactModal__Content {
        transform: translateY(0px) !important;      
      }
    }
    
    .ReactModal__Overlay--before-close {
      opacity: 0 !important;
      
      .ReactModal__Content {
        transform: translateY(-100%) !important;    
      }
    }
    
    #___gatsby {
        min-height: 100vh;
    }
    
    #gatsby-focus-wrapper {
        min-height: 100vh;
    }
`;

    return (
        <React.Fragment>
            <GlobalStyle
                theme="purple"
                noImageBg={noImageBg}/>
            {children}
        </React.Fragment>
    )
};

Modal.setAppElement('#___gatsby');

Layout.defaultProps = {
    imageBgUrl: '',
};

export default Layout;
