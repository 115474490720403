import React from 'react';
import { Link } from 'gatsby';
import { StyledHeader, HeaderLogoContainer } from './styled';

const Header = ({ noPadding, assetsPath }) => (
  <StyledHeader noPadding={noPadding}>
      <Link to={`/`}>
          {/*<HeaderLogoContainer>*/}
          {/*    <img src={`${assetsPath}/logo.svg`} alt="Art Basel"/>*/}
          {/*</HeaderLogoContainer>*/}
          <p>the nautilus room</p>
      </Link>
  </StyledHeader>
);

export default Header;
